<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-confirm-on': this.state == 'confirm',
        'login-forgot-on': this.state == 'forgot',
        'login-reset-on': this.state == 'reset',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-none flex-column flex-row-auto d-lg-flex"
        style="background-color: #F2C98A;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo-letter-1.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923;"
          >
            {{ $t('project.selectBookWithNewSys1') }} <br />{{ $t('project.selectBookWithNewSys2') }}
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          style="background-size: contain"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative p-7 mx-lg-auto"
      >
        <div class="d-flex flex-row justify-content-between align-items-center">
          <router-link to="/home">
            <span class="svg-icon svg-icon-4x svg-icon-warning">
              <inline-svg
                src="media/svg/icons/Navigation/Angle-double-left.svg"
              />
            </span>
          </router-link>
          <KTDropdownLanguage />
        </div>


        <div class="d-flex flex-column-fluid flex-center overflow-hidden">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form-wrapper :validator="$v.loginForm" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('project.welcome') }}
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                >{{ $t('project.areYouNew') }}
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    style="cursor: pointer"
                    @click="showForm('signup')"
                  >{{ $t('project.createAnAccount') }}</a
                  ></span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">
                  {{ $t('common.phone') }}
                </label>
                <b-row>
                  <form-group name="phone" xs="12" no-label no-margin>
                    <b-input
                        slot-scope="{ attrs, listeners }"
                        v-bind="attrs"
                        v-on="listeners"
                        v-mask="'0(###) ### ####'"
                        class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                        v-model="loginForm.phone"
                    />
                  </form-group>
                </b-row>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">
                    {{ $t('project.password') }}
                  </label>
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    style="cursor: pointer"
                    @click="showForm('forgot')"
                  >{{ $t('project.forgetPassword') }}</a
                  >
                </div>
                <b-row>
                  <form-group name="password" xs="12" no-label no-margin>
                    <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      type="password"
                      @keypress.enter="submitLogin"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="loginForm.password"
                    />
                  </form-group>
                </b-row>
              </div>
              <div class="pb-lg-0 pb-5">
                <b-button
                  size="lg"
                  variant="primary"
                  @click="submitLogin"
                  :disabled="$v.loginForm.$anyError || submitting"
                  class="font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.login') }} <b-spinner small v-if="submitting" />
                </b-button>
              </div>
            </form-wrapper>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form-wrapper :validator="$v.registerForm" id="kt_login_signup_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('project.register') }}
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                > Zaten bir hesabınız var mı?
                  <a
                      id="kt_login_signin"
                      class="text-primary font-weight-bolder"
                      style="cursor: pointer"
                      @click="showForm('signin')"
                  >Giriş Yapın</a
                  ></span
                >
              </div>
              <b-row class="form-group">
                <form-group name="firstName" xs="12" md="6" no-label no-margin class="mb-7 mb-md-0">
                  <b-input
                    slot-scope="{ attrs, listeners }"
                    v-bind="attrs"
                    v-on="listeners"
                    :placeholder="$t('project.firstName')"
                    class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                    v-model="registerForm.firstName"
                  />
                </form-group>
                <form-group name="lastName" xs="12" md="6" no-label no-margin>
                  <b-input
                    slot-scope="{ attrs, listeners }"
                    v-bind="attrs"
                    v-on="listeners"
                    :placeholder="$t('project.lastName')"
                    class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                    v-model="registerForm.lastName"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group">
                <form-group name="email" xs="12" no-label no-margin>
                  <b-input
                    slot-scope="{ attrs, listeners }"
                    v-bind="attrs"
                    v-on="listeners"
                    :placeholder="$t('project.email')"
                    class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                    v-model="registerForm.email"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group">
                <form-group name="phone" xs="12" no-label no-margin>
                  <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      placeholder="Telefon"
                      v-mask="'0(###) ### ####'"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="registerForm.phone"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group">
                <form-group name="password" xs="12" no-label no-margin>
                  <b-input
                    slot-scope="{ attrs, listeners }"
                    v-bind="attrs"
                    v-on="listeners"
                    type="password"
                    :placeholder="$t('project.password')"
                    class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                    v-model="registerForm.password"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group">
                <form-group name="reNewPassword" xs="12" no-label no-margin>
                  <b-input
                    slot-scope="{ attrs, listeners }"
                    v-bind="attrs"
                    v-on="listeners"
                    :placeholder="$t('project.reNewPassword')"
                    type="password"
                    class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                    v-model="registerForm.reNewPassword"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group" v-if="$i18n.locale === 'tr'">
                <form-group name="accept" xs="12" no-label no-margin>
                  <label class="checkbox mb-0" slot-scope="{ attrs, listeners }">
                    <input
                      v-bind="attrs"
                      v-on="listeners"
                      type="checkbox"
                      name="agree"
                      v-model="registerForm.accept"
                    />
                    <span class="mr-2"></span>
                    <a href="#" class="ml-2 mr-1">
                      Gizlilik ve kullanım koşullarını
                    </a>
                    kabul ediyorum.
                  </label>
                </form-group>
              </b-row>
              <div class="form-group" v-if="$i18n.locale === 'en'">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I agree to the
                  <a href="#" class="ml-2 mr-1">
                    Privacy and Terms of Use
                  </a>
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <b-button
                  size="lg"
                  variant="primary"
                  @click="checkEmailAndPhone"
                  :disabled="$v.registerForm.$anyError || submitting"
                  class="font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.register') }} <b-spinner v-if="submitting" />
                </b-button>
              </div>
            </form-wrapper>
          </div>
          <div class="login-form login-confirm">
            <form-wrapper :validator="$v.confirmForm" id="kt_login_confirm_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Doğrula
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Lütfen telefon numaranıza gönderilen doğrulama kodunu girin.
                </p>
              </div>
              <b-row class="form-group">
                <form-group name="code" xs="12" no-label no-margin>
                  <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      placeholder="Doğrulama Kodu"
                      v-mask="'######'"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="confirmForm.code"
                  />
                </form-group>
              </b-row>

              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <b-button
                    size="lg"
                    variant="primary"
                    @click="submitRegister"
                    :disabled="$v.registerForm.$anyError || submitting"
                    class="font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.register') }} <b-spinner v-if="submitting" />
                </b-button>
                <b-button
                    size="lg"
                    variant="primary"
                    @click="showForm('signup')"
                    class="btn-light-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.cancel') }}
                </b-button>
              </div>
            </form-wrapper>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <form-wrapper :validator="$v.forgotForm" id="kt_login_forgot_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('project.forgetPassword') }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t('project.enterPhoneToResetPassword') }}
                </p>
              </div>
              <b-row class="form-group">
                <form-group name="phone" xs="12" no-label no-margin>
                  <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      placeholder="Telefon"
                      v-mask="'0(###) ### ####'"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="forgotForm.phone"
                  />
                </form-group>
              </b-row>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <b-button
                  size="lg"
                  variant="primary"
                  @click="sendForgotCode"
                  :disabled="$v.forgotForm.phone.$anyError || submitting"
                  class="font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('common.next') }} <b-spinner small v-if="submitting" />
                </b-button>
                <b-button
                  size="lg"
                  variant="primary"
                  @click="showForm('signin')"
                  class="btn-light-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.cancel') }}
                </b-button>
              </div>
            </form-wrapper>
          </div>
          <div class="login-form login-reset">
            <form-wrapper :validator="$v.forgotForm" id="kt_login_reset_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Doğrula
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Lütfen telefon numaranıza gönderilen doğrulama kodunu girin.
                </p>
              </div>
              <b-row class="form-group">
                <form-group name="code" xs="12" no-label no-margin>
                  <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      placeholder="Doğrulama Kodu"
                      v-mask="'######'"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="forgotForm.code"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group">
                <form-group name="password" xs="12" no-label no-margin>
                  <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      type="password"
                      :placeholder="$t('project.password')"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="forgotForm.password"
                  />
                </form-group>
              </b-row>
              <b-row class="form-group">
                <form-group name="reNewPassword" xs="12" no-label no-margin>
                  <b-input
                      slot-scope="{ attrs, listeners }"
                      v-bind="attrs"
                      v-on="listeners"
                      :placeholder="$t('project.reNewPassword')"
                      type="password"
                      class="form-control-lg form-control-solid h-auto py-7 px-6 rounded-lg"
                      v-model="forgotForm.reNewPassword"
                  />
                </form-group>
              </b-row>

              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <b-button
                    size="lg"
                    variant="primary"
                    @click="submitResetPassword"
                    :disabled="$v.forgotForm.$anyError || submitting"
                    class="font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.send') }} <b-spinner v-if="submitting" />
                </b-button>
                <b-button
                    size="lg"
                    variant="primary"
                    @click="showForm('signin')"
                    class="btn-light-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  {{ $t('project.cancel') }}
                </b-button>
              </div>
            </form-wrapper>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>

import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
// import Swal from "sweetalert2";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage";
import {minLength, required, email, sameAs} from "vuelidate/lib/validators";
import {formatPhone} from "@/core/utils/index";

export default {
  components: {
    KTDropdownLanguage,
  },
  validations: {
    loginForm: {
      //email: { required, email },
      phone: { required, minLength: minLength(15) },
      password: { required },
    },
    registerForm: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phone: { required, minLength: minLength(15) },
      password: { required, minLength: minLength(6) },
      reNewPassword: { sameAs: sameAs('password') },
      accept: { needToConfirmPrivacyAndPolicy: value => value }
    },
    confirmForm: {
      code: { required, minLength: minLength(6) },
    },
    forgotForm: {
      phone: {
        required,
        minLength: minLength(15)
      },
      code: { required, minLength: minLength(6) },
      password: { required, minLength: minLength(6) },
      reNewPassword: { sameAs: sameAs('password') },
    },
  },
  created() {
    if(this.currentUser.hasOwnProperty('id')){
      this.$router.push('profile/qrCode')
    }
    if(this.$route.name === 'register'){
      this.showForm('signup')
    }
  },
  data() {
    return {
      state: "signin",
      loginForm: {
        email: '',
        phone: '',
        password: ''
      },
      registerForm: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        reNewPassword: '',
        accept: false
      },
      confirmForm: {
        code: '',
      },
      forgotForm: {
        phone: '',
        code: '',
        password: '',
        reNewPassword: '',
      },
      submitting: false
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/working.svg";
    }
  },
  methods: {
    async submitLogin(){
      try {

        this.submitting = true;
        this.$store.dispatch(LOGOUT);

        await this.$store.dispatch(LOGIN, {
          ...this.loginForm,
          phone: formatPhone(this.loginForm.phone)
        });

        this.$router.push('profile/qrCode');

      }catch (e) {
        this.toast({ type: 'danger', message: 'wrongCredentials' });
        console.log(e);
      }finally {
        this.submitting = false;
      }
    },
    async checkEmailAndPhone() {
      try {

        this.$v.registerForm.$touch();
        if (this.$v.registerForm.$anyError) {
          this.toast({ type: 'danger', message: 'validationError' });
          return false;
        }

        const {data} = await this.axios.post('auth/checkEmailAndPhone', {
          phone: formatPhone(this.registerForm.phone),
          email: this.registerForm.email
        });

        if(data.email){
          this.toast({ type: 'danger', message: 'emailAlreadyUsed' });
          return;
        }

        if(data.phone){
          this.toast({ type: 'danger', message: 'phoneAlreadyUsed' });
          return;
        }

        this.sendConfirmCode()
      } catch (e) {
        console.log(e);
      }
    },
    async sendForgotCode() {
      try {
        this.$v.forgotForm.phone.$touch();
        if (this.$v.forgotForm.phone.$anyError) {
          this.toast({ type: 'danger', message: 'validationError' });
          return false;
        }

        await this.axios.post('auth/sendPhoneForgotCode', {
          phone: formatPhone(this.forgotForm.phone)
        });

        this.showForm('reset')
      } catch (e) {
        if(e.response.status === 404)
          this.toast({ type: 'danger', message: 'noSuchAUserHavingPhone' });

        console.log(e);
      }
    },
    async submitResetPassword(){
      try {

        this.$v.forgotForm.$touch();
        if (this.$v.forgotForm.$anyError) {
          this.toast({ type: 'danger', message: 'validationError' });
          return false;
        }

        this.submitting = true;
        await this.axios.post('auth/resetPassword', {
          ...this.forgotForm,
          phone: formatPhone(this.forgotForm.phone)
        });
        this.toast({ message: 'updateSuccess', item: 'password' });
        this.$store.dispatch(LOGOUT);

        await this.$store.dispatch(LOGIN, {
          password: this.forgotForm.password,
          email: '',
          phone: formatPhone(this.forgotForm.phone)
        });

        this.$router.push('profile/qrCode');

      }catch (e) {
        if(e.response.status === 409){
          this.toast({ type: 'danger', message: 'confirmationCodeNotCorrect' });
        } else {
          this.toast({ type: 'danger', message: 'somethingWentWrong' });
          console.log(e);

        }
      }finally {
        this.submitting = false;
      }
    },
    async sendConfirmCode() {
      try {
        await this.axios.post('auth/sendPhoneConfirmCode', {
          phone: formatPhone(this.registerForm.phone)
        });

        this.showForm('confirm')
      } catch (e) {
        console.log(e);
      }
    },
    async submitRegister(){
      try {

        this.$v.confirmForm.$touch();
        if (this.$v.confirmForm.$anyError) {
          this.toast({ type: 'danger', message: 'validationError' });
          return false;
        }

        this.submitting = true;
        this.$store.dispatch(LOGOUT);

        await this.$store.dispatch(REGISTER, {
          ...this.registerForm,
          phone: formatPhone(this.registerForm.phone),
          ...this.confirmForm
        });
        this.$router.push('profile/qrCode');

      }catch (e) {
        if(e.response.status === 409){
          this.toast({ type: 'danger', message: 'confirmationCodeNotCorrect' });

        } else {
          this.toast({ type: 'danger', message: 'somethingWentWrong' });
          console.log(e);

        }
      }finally {
        this.submitting = false;
      }
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );

    }
  }
};
</script>
